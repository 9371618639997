import { CheckIcon } from "@chakra-ui/icons";
import React from "react";

const CardIcon = (props) => {
    const { icon, isSelected, ...rest } = props;
    if (icon || isSelected) {
        return (
            <React.Fragment>
                {icon != null ? (
                    <i
                        className={"zen-icon zen-icon--alternate-dark zen-icon--text-lg fontello icon-" + icon}
                        {...rest}
                    />
                ) : (
                    <CheckIcon />
                )}
                <style jsx>
                    {`
                    .zen-icon {
                        font-size: 25px;
                    }
                    @media screen and (min-width: 21em) {
                        .zen-icon {
                            font-size: 30px;
                        }
                    }
                    @media screen and (min-width: 48em) {
                        .zen-icon {
                            font-size: 35px;
                        }
                    }
                    @media screen and (min-width: 65em) {
                        .zen-icon {
                            font-size: 40px;
                        }
                `}
                </style>
            </React.Fragment>
        );
    }
    if (!icon) {
        return null;
    }
};

export default CardIcon;
