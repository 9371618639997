import React from "react";
import styles from "./DetailsText.module.scss";

const DetailsText = (props) => {
    const { html } = props;

    function createMarkup(html) {
        return { __html: html };
    }

    return (
        <React.Fragment>
            <div
                className={styles.detailsText}
                dangerouslySetInnerHTML={createMarkup(html)}
            />
        </React.Fragment>
    );
};

export default DetailsText;
