import { User } from "firebase/auth";
import { getCurrentBaseUrl } from "util/helpers";

/**
 * This function returns an HMAC token for the given data and secret.
 * This is used as an authorization header in our API calls.
 *
 * For our `key` we combine the `apiKey` and `requestPath`.
 *
 * https://nodejs.org/api/crypto.html#cryptocreatehmacalgorithm-key-options
 */

const baseUrl = getCurrentBaseUrl();

export const generateServicesAuthToken = async (requestPath) => {
    const { createHmac } = await import("crypto");
    const apiKey = process.env.SERVICES_API_KEY;
    const apiSecret = process.env.SERVICES_API_SECRET;
    const combinedKey = apiKey + requestPath;
    let token = createHmac("sha256", apiSecret).update(combinedKey).digest("hex");
    let base64Token = Buffer.from(token).toString("base64");
    return base64Token;
};

export const fetchBookingData = async (bookingId: number) => {
    try {
        // http://admin-services-env.eba-amt9is3r.us-east-1.elasticbeanstalk.com/docs/#booking-GETapi-v1-booking-show--id-

        let requestPath = "api/v1/booking/show/" + bookingId;
        let authToken = await generateServicesAuthToken(requestPath);
        let url: any = new URL(process.env.SERVICES_API_BASEURL + requestPath);

        let headers = {
            Authorization: authToken,
            "Content-Type": "application/json",
            Accept: "application/json",
            "Api-Version": "v1",
            //"User-ID": databaseId,
        };

        let response = await fetch(url, {
            method: "GET",
            headers,
        });

        let data = await response.json();
        return data;
    } catch (err) {
        // Log error to Sentry
        throw new Error(err);
    }
};

export const confirmExternalBooking = async (stripeSession: any, bookingData: any) => {
    let url: string = `${baseUrl}/api/admin-services/external-booking/update/${bookingData.id}`;
    let response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
            stripeSession,
            bookingData,
        }),
    });
    return response;
};

/**
 * Fetch Quiz Options
 *
 * This function can either retrieve all available quiz options, or the selected quiz options for a specific customer.
 * API reference: https://services.vacayou.com/docs#customer-preference-GETapi-v1-customer-preference-show--id--
 *
 * @param referringUrl
 * @param databaseId
 * @returns
 */
export const fetchQuizOptions = async (referringUrl: URL | string, databaseId: string | number | null) => {
    let url: string = `${baseUrl}/api/admin-services/customer-preference/show`;
    let response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
            referringUrl,
            databaseId,
        }),
    });
    let responseJson = await response.json();
    return responseJson;
};

/**
 * Search for a Customer
 *
 * This function returns customer records that have a specified email address using the Customer SEARCH endpoint.
 * https://services.vacayou.com/docs#customer-POSTapi-v1-customer-search
 *
 * @param email
 * @returns
 */
export const fetchCustomerRecordForFirebaseUser = async (user: User) => {
    let searchObject = {
        firebase_id: user.uid,
    };
    let searchResponse = await fetch(`${baseUrl}/api/admin-services/customer/search`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(searchObject),
    });
    let responseJson = searchResponse.json();
    return responseJson;
};

/**
 * Save Quiz Selections
 *
 * This function saves the customer's quiz selections into the customer preferences table.
 * https://services.vacayou.com/docs#customer-preference-PUTapi-v1-customer-preference-update--id-
 *
 * @param quizSelections
 * @param customerId
 * @returns
 */
export const saveQuizSelections = async (quizSelections: [number], customerId: number) => {
    let url: string = `${baseUrl}/api/admin-services/customer-preference/update`;
    let response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({ quizSelections: quizSelections, customerId: customerId }),
    });
    return response;
};

/**
 * Fetch a Vacayou Customer record by ID
 * @param id
 * @returns
 */

export const fetchCustomerRecordById = async (id: number | string) => {
    let body = { id };
    let customer = await fetch("/api/admin-services/customer/show", {
        body: JSON.stringify(body),
        method: "POST",
    }).then((response) => response.json());

    return customer;
};

/**
 * Create a Basic Customer Record
 *
 * @param user
 * @param subscriptionType
 * @param orgAlias
 * @returns
 */
export const createCustomerRecord = async (user, subscriptionType, orgAlias, freeTrialExpiration) => {
    let body = { user, subscriptionType, orgAlias, freeTrialExpiration };

    let customerStoreResponse = await fetch("/api/admin-services/customer/store/", {
        body: JSON.stringify(body),
        method: "POST",
    }).then((response) => response.json());

    return customerStoreResponse;
};

/**
 * Update Customer Record
 *
 * This function accepts a customerId and an object of data to update
 * https://services.vacayou.com/docs#customer-PUTapi-v1-customer-update--id-
 *
 * @param customerId
 * @param dataToUpdate {object}
 */
export const updateCustomerRecord = async (customerId: number, dataToUpdate: any) => {
    if (customerId) {
        let url: string = `${baseUrl}/api/admin-services/customer/update`;
        let response = await fetch(url, {
            method: "POST",
            body: JSON.stringify({ customerId: customerId, dataToUpdate: { ...dataToUpdate } }),
        });
        return response;
    } else {
        return { status: 500, message: "No customer ID supplied" };
    }
};
