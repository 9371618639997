import { Heading, useStyleConfig, useBreakpointValue } from "@chakra-ui/react";

const DetailsHeader = (props) => {
    const { variant, label } = props;
    const styles = useStyleConfig("PackageDetailsHeader", { variant });

    return (
        <Heading sx={styles} {...props}>
            {label}
        </Heading>
    );
};

export default DetailsHeader;
