import { createContext, ReactNode, useContext, useState } from "react";
import { auth } from "@/services/firebase";
import {
    fetchCustomerRecordForFirebaseUser,
    createCustomerRecord,
    fetchCustomerRecordById,
} from "@/services/admin-service";
import {
    User,
    AuthProvider,
    UserCredential,
    signInWithPopup,
    FacebookAuthProvider,
    GoogleAuthProvider,
    sendPasswordResetEmail,
    sendEmailVerification,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendSignInLinkToEmail,
    linkWithPopup,
    unlink,
    isSignInWithEmailLink,
    updateProfile,
    signInWithEmailLink,
    getAdditionalUserInfo,
} from "firebase/auth";
// TODO replace with useToast in DEV-1441
import { usePopup } from "./usePopup";
import { UserContext } from "../../contexts/userContext";
import { getCurrentBaseUrl } from "../../util/helpers";

export enum SubscriptionTypes {
    standard = "STANDARD",
    voyager = "VOYAGER",
}

export enum AuthenticationErrorTypes {
    USER_NOT_FOUND = "auth/user-not-found",
    EMAIL_BAD_FORMAT = "auth/invalid-email",
    ARGUMENT_ERROR = "auth/argument-error",
    WRONG_PASSWORD = "auth/wrong-password",
    EXISTS_WITH_DIFFERENT_PROVIDER = "auth/account-exists-with-different-credential",
}

export const authContext = createContext(null);
const { Provider } = authContext;

const userAuthProvider = () => {
    const [user, setUser] = useState<User>(null);
    const [customer, setCustomer] = useState<any>(null);
    const [databaseId, setDatabaseId] = useState<string>(null);
    const [userClaims, setUserClaims] = useState<any>(null);
    const [isVoyager, setIsVoyager] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [isRegistering, setIsRegistering] = useState(false);
    const { showPopup } = usePopup();
    const { userState, setUserState, b2bState, setB2bState } = useContext(UserContext);
    const baseUrl = getCurrentBaseUrl();

    const handleAuthChange = async (_user: User) => {
        setIsLoading(true);
        if (_user && _user !== null) {
            setUser(_user);
            if (customer == null) {
                await updateCustomerStateFromDatabase(_user);
            }
            _user.getIdToken().then((idToken) => {
                localStorage.setItem("jwt", idToken);
            });
            _user.getIdTokenResult(true).then((idTokenResult) => {
                console.log("idTokenResult", idTokenResult);
                setUserClaims(idTokenResult.claims);
                if ("databaseId" in idTokenResult.claims) {
                    setDatabaseId(idTokenResult.claims.databaseId.toString());
                }
                if ("subscriptionType" in idTokenResult.claims) {
                    let subscriptionType = idTokenResult.claims.subscriptionType;
                    localStorage.setItem("subscriptionType", subscriptionType);
                    localStorage.setItem("authTime", idTokenResult.authTime);
                    setIsVoyager(subscriptionType == SubscriptionTypes.voyager);
                    setUserState({ ...userState, userType: subscriptionType });
                }
                if ("orgAlias" in idTokenResult.claims) {
                    let orgAlias = idTokenResult.claims.orgAlias;
                    localStorage.setItem("orgAlias", orgAlias);
                }
                setIsLoggingIn(false);
                setIsRegistering(false);
                setIsLoading(false);
            });
        } else {
            // Reset state variables
            // TODO break this into a function for default values (DEV-1464)
            // Hint: this is a potential candidate for a reducer
            setCustomer(null);
            setDatabaseId(null);
            setIsVoyager(false);
            setIsLoading(false);
            setIsLoggingIn(false);
        }
    };

    const updateCustomerStateFromDatabase = async (user) => {
        if (user && user !== null) {
            let customerSearchResponse = await fetchCustomerRecordForFirebaseUser(user);
            if (Object.keys(customerSearchResponse).length > 0) {
                const customer = await fetchCustomerRecordById(customerSearchResponse[0].id);
                setCustomer(customer);
            } else {
                setCustomer(null);
            }
        }
    };

    const _loginWithProvider = (provider: AuthProvider): Promise<UserCredential> => {
        setIsLoggingIn(true);

        return signInWithPopup(auth, provider)
            .then(async (result) => {
                showPopup({ message: "Logged in successfully!" });
                return result;
            })
            .catch((error) => {
                setIsLoggingIn(false);
                return Promise.reject(error);
            });
    };

    const loginWithGoogle = (): Promise<UserCredential> => {
        const provider = new GoogleAuthProvider();
        auth.useDeviceLanguage();
        return _loginWithProvider(provider);
    };

    const loginWithFacebook = (): Promise<UserCredential> => {
        const provider = new FacebookAuthProvider();
        provider.addScope("email");
        auth.useDeviceLanguage();
        return _loginWithProvider(provider);
    };

    const updateFirebaseProfile = ({ phoneNumber, ...payload }: Partial<User>): Promise<void> => {
        // TODO: implement phone number handling

        return updateProfile(user, payload).then(() => {
            showPopup({ message: "Profile updated!" });
        });
    };

    const resetPassword = (email = user?.email): Promise<void> => {
        return sendPasswordResetEmail(auth, email, {
            url: document.location.toString(),
        })
            .then(() =>
                showPopup({
                    message: "Please check your email for the reset link.",
                })
            )
            .catch((error) => {
                if (AuthenticationErrorTypes.USER_NOT_FOUND == error.code) {
                    showPopup({
                        message: "User not found with that email.",
                        type: "WARNING",
                    });
                    return Promise.reject(error);
                }
                console.error(error);
            });
    };

    const sendEmailVerification = (): Promise<any> => {
        return sendEmailVerification().then(() => {
            showPopup({
                message: "Please check your email for the verification link.",
            });
        });
    };

    const logout = (): Promise<void> => {
        setB2bState({
            ...b2bState,
            organization: "",
            logo: { desktop: "", mobile: "" },
        });
        setCustomer(null);
        setUserState({ ...userState, userType: "VISITOR" });
        localStorage.clear();
        return auth.signOut().then(() => setUser(null));
    };

    const loginWithEmail = (email: string, password: string): Promise<UserCredential> => {
        setIsLoggingIn(true);
        return signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                showPopup({ message: "Logged in successfully!" });
                return Promise.resolve(userCredential);
            })
            .catch((error) => {
                const errorCode = error.code as AuthenticationErrorTypes;
                switch (errorCode) {
                    case AuthenticationErrorTypes.USER_NOT_FOUND:
                        showPopup({
                            message: `Oops! Can't find that user, are you sure you're registered?`,
                            type: "WARNING",
                        });
                        break;
                    case AuthenticationErrorTypes.EMAIL_BAD_FORMAT:
                        showPopup({
                            message: `Please enter valid details.`,
                            type: "WARNING",
                        });
                        break;
                    case AuthenticationErrorTypes.WRONG_PASSWORD:
                        showPopup({
                            message: `Invalid password.`,
                            type: "WARNING",
                        });
                        break;
                    default:
                        showPopup({
                            message: `Unable to login with those details.`,
                            type: "ERROR",
                        });
                }
                setIsLoggingIn(false);
                return Promise.resolve(error);
            });
    };

    const registerWithEmailAndPassword = (email: string, password: string): Promise<UserCredential> => {
        setIsRegistering(true);
        return createUserWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
                setIsRegistering(false);
                return userCredential;
            })
            .catch((error) => {
                setIsRegistering(false);
                return Promise.reject(error);
            });
    };

    const registerWithEmailOnly = async (email: string, orgAlias?: string) => {
        var actionCodeSettings = {
            // URL you want to redirect back to. The domain (www.example.com) for this
            // URL must be in the authorized domains list in the Firebase Console.
            url: `${baseUrl}/account/verify?orgAlias=${orgAlias}`,
            // This must be true.
            handleCodeInApp: true,
            //dynamicLinkDomain: "link.vacayou.com",
        };
        return sendSignInLinkToEmail(auth, email, actionCodeSettings)
            .then(async (userCredential) => {
                localStorage.setItem("emailForSignIn", email);
                localStorage.setItem("orgAlias", orgAlias);
                setIsRegistering(false);
                return { emailSent: true, userCredential };
            })
            .catch((error) => {
                setIsRegistering(false);
                return { emailSent: false, error };
            });
    };

    const connectWithProvider = (provider: AuthProvider): Promise<UserCredential> => {
        return linkWithPopup(auth.currentUser, provider).then((_userCredential) => {
            setUser(_userCredential.user);
            return _userCredential;
        });
    };

    const disconnectFromProvider = (providerId: string): Promise<User> => {
        return unlink(auth.currentUser, providerId).then((_user) => {
            setUser(_user);
            return _user;
        });
    };

    const createNewCustomerRecord = async (user, subscriptionType, orgAlias, freeTrialExpiration) => {
        let customerStoreResponse = createCustomerRecord(user, subscriptionType, orgAlias, freeTrialExpiration)
            .then(async (customerStoreResponse) => {
                // Upgrade user to the Voyager status
                let customClaimsResponse = await setCustomClaims(
                    user,
                    subscriptionType,
                    orgAlias,
                    customerStoreResponse.id
                );
                return customClaimsResponse;
            })
            .then(async () => {
                // Update the idToken
                await handleAuthChange(user);
            })
            .then(() => {
                return { status: "success" };
            })
            .catch((error) => {
                return { status: "error", error: error };
            });

        return customerStoreResponse;
    };

    const setCustomClaims = async (user: any, subscriptionType: string, orgAlias?: string, databaseId?: number) => {
        const body = {
            subscriptionType: subscriptionType,
            orgAlias: orgAlias ? orgAlias : null,
            databaseId: databaseId ? databaseId : null,
        };

        const response = await fetch(`/api/firebase/setCustomUserClaims/${user.uid}`, {
            method: "POST",
            body: JSON.stringify(body),
        });

        return response;
    };

    const changeExistingUserSubscriptionType = async (user, desiredSubscriptionType) => {
        return new Promise(function (resolve, reject) {
            if (user && desiredSubscriptionType) {
                // Update the claims object
                user.getIdTokenResult(true)
                    .then(async (idTokenResult) => {
                        setCustomClaims(
                            user,
                            desiredSubscriptionType,
                            idTokenResult.claims.orgAlias,
                            idTokenResult.claims.databaseId
                        );
                    })
                    .then(async () => {
                        await handleAuthChange(user);
                    })
                    .then(async () => {
                        resolve({ success: true, message: `subscriptionType set to ${desiredSubscriptionType}` });
                    })
                    .catch(() => {
                        reject(Error(`There was an error setting the subscriptionType`));
                    });
            } else {
                reject(Error(`Unable to set subscriptionType`));
            }
        });
    };

    // Function that verifies the user from the Firebase email, then upgrades the user state if needed
    const handleEmailVerification = (fullVerificatoinPageUrl, orgAlias, setErrorState) => {
        // Confirm the link is a sign-in with email link.
        if (isSignInWithEmailLink(auth, fullVerificatoinPageUrl)) {
            // Additional state parameters can also be passed via URL.
            // This can be used to continue the user's intended action before triggering
            // the sign-in operation.
            // Get the email if available. This should be available if the user completes
            // the flow on the same device where they started it.
            var email = localStorage.getItem("emailForSignIn");
            if (!email) {
                // User opened the link on a different device. To prevent session fixation
                // attacks, ask the user to provide the associated email again. For example:
                email = prompt("Please provide your email for confirmation");
            }

            // The client SDK will parse the code from the link for you.
            signInWithEmailLink(auth, email, fullVerificatoinPageUrl)
                .then((result) => {
                    // Clear email from storage.
                    localStorage.removeItem("emailForSignIn");

                    // You can access the new user via result.user
                    const { user } = result;

                    // Additional user info profile not available via:
                    // result.additionalUserInfo.profile == null
                    // You can check if the user is new or existing:
                    // result.additionalUserInfo.isNewUser
                    let isNewUser = getAdditionalUserInfo(result).isNewUser;

                    // If this user is a new user
                    if (isNewUser) {
                        createNewCustomerRecord(user, SubscriptionTypes.voyager, orgAlias, false).then((response) => {
                            if (response) {
                                return { status: "success", isNewUser: true, ...response };
                            } else {
                                //Error
                                setErrorState(true);
                                return {
                                    status: "error",
                                    message: "There was an issue when creating a new customer record.",
                                };
                            }
                        });
                    } else {
                        return { status: "success", isNewUser: false };
                    }
                })
                .catch((error) => {
                    // Some error occurred, you can inspect the code: error.code
                    // Common errors could be invalid email and invalid or expired OTPs.
                    setErrorState(true);
                    return {
                        status: "error",
                        message: "There was an issue verifying this user.",
                    };
                    // TODO: Handle common error codes and present user with a better message.
                });
        }
    };

    return {
        isLoading,
        setIsLoading,

        user,
        setUser,

        userClaims,

        isVoyager,
        setIsVoyager,

        isRegistering,
        setIsRegistering,

        isLoggingIn,
        setIsLoggingIn,

        loginWithGoogle,
        handleAuthChange,
        loginWithFacebook,
        updateFirebaseProfile,
        resetPassword,
        sendEmailVerification,
        logout,
        loginWithEmail,
        registerWithEmailAndPassword,
        registerWithEmailOnly,
        connectWithProvider,
        disconnectFromProvider,
        createNewCustomerRecord,
        setCustomClaims,
        changeExistingUserSubscriptionType,
        handleEmailVerification,

        databaseId,
        customer,
        updateCustomerStateFromDatabase,
    };
};

// Wrapper using which we are providing our useAuth
export function UserAuthProvider(props: { children: ReactNode }): JSX.Element {
    const _auth = userAuthProvider();
    return <Provider value={_auth}>{props.children}</Provider>;
}

// Hook which returns a function which contains @AuthContextResponse
export const useAuth = () => useContext(authContext);
