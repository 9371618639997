import { RichText } from "prismic-reactjs";
import { Box, Flex, Heading, Image, Spacer } from "@chakra-ui/react";
import { SignUpButton } from "../../../common";

const MembershipCard = (props) => {
    const { header, subheader, copy, badge, price, isActive } = props;

    return (
        <Box h="100%" borderRadius="lg" overflow="hidden" boxShadow="primary">
            <Flex direction="column" h="100%">
                <Flex direction="column" h="100%" py={6} px={5} background="platinum.500">
                    {subheader && (
                        <Heading fontSize="sm" fontWeight="normal" color="prussianBlue" textTransform="none" mb={1}>
                            {subheader}
                        </Heading>
                    )}
                    <Heading fontSize="md" fontWeight="bold" color="prussianBlue" mb={6}>
                        {header}
                    </Heading>
                    <Box fontSize="sm" color="prussianBlue">
                        {RichText.render(copy)}
                    </Box>
                    <Spacer />
                    {badge && <Image src={badge} mt={6} mx="auto" width="100px" />}
                </Flex>
                <Flex
                    direction="row"
                    background="prussianBlue"
                    alignItems="center"
                    justifyContent={isActive ? "space-between" : "space-around"}
                    p={4}
                    minHeight="71px"
                >
                    <Heading fontSize="lg" fontWeight="bold" color="white" textTransform="none">
                        {price}
                    </Heading>
                    {isActive && <SignUpButton color="prussianBlue" colorScheme="platinum" />}
                </Flex>
            </Flex>
        </Box>
    );
};

export default MembershipCard;
