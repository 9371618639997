import React from "react";
import NextLink from "next/link";
import { Button as ChakraButton, Link } from "@chakra-ui/react";

export default function Button(props) {
    const { border, color, colorScheme, isExternal, isLoading, href, label, onClick } = props;

    return (
        <NextLink href={href || "#"} passHref>
            <Link isExternal={isExternal}>
                <ChakraButton
                    as="button"
                    color={color}
                    colorScheme={colorScheme}
                    border={border}
                    onClick={onClick}
                    isLoading={isLoading}
                >
                    {label}
                </ChakraButton>
            </Link>
        </NextLink>
    );
}
