import { Box, Container, Flex, Grid, GridItem, Heading } from "@chakra-ui/react";
import { RichText } from "prismic-reactjs";
import { MembershipCard } from "../";

const MembershipContainer = ({ cards, copy, header, subheader }) => {
    return (
        <Container maxW={cards.length < 3 ? "container.md" : "container.lg"} py={16} position="relative">
            <Flex direction="column" mb={7}>
                <Heading
                    fontFamily="Lato"
                    fontSize="2xl"
                    fontWeight="bold"
                    color="white"
                    textAlign="center"
                    textTransform="uppercase"
                    mb={4}
                >
                    {header}
                </Heading>
                {subheader && (
                    <Heading
                        fontFamily="Lato"
                        fontSize="md"
                        fontWeight="bold"
                        color="white"
                        textAlign="center"
                        textTransform="uppercase"
                        mb={4}
                    >
                        {subheader}
                    </Heading>
                )}

                <Box fontFamily="Lato" fontSize="md" fontWeight="regular" color="white" textAlign="center">
                    {RichText.render(copy)}
                </Box>
            </Flex>
            <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(12, 1fr)" }} gap={{ base: 6, lg: 12 }}>
                {cards &&
                    cards.map((card, i) => {
                        return (
                            <GridItem
                                colSpan={{ base: 1, md: cards.length > 1 ? (cards.length === 3 ? 4 : 6) : 12 }}
                                key={i}
                            >
                                <MembershipCard
                                    header={card.card_header}
                                    subheader={card.card_subheader}
                                    copy={card.card_copy}
                                    badge={card.card_badge}
                                    price={card.card_price}
                                    isActive={card.is_active}
                                />
                            </GridItem>
                        );
                    })}
            </Grid>
        </Container>
    );
};

export default MembershipContainer;
