import NextLink from "next/link";
import { Box, Heading, Link, Spacer, Tag, Text } from "@chakra-ui/react";
import { Card } from "../../../common";
import { generateAssetZenURL } from "../../../../util/helpers";

const PerkCard = ({ caption, discount, imageId, link, subTitle, title }) => {
    return (
        <NextLink href={link} passHref>
            <Link>
                <Card variant="perk" aspectRatio={4 / 5} bgImage={generateAssetZenURL(imageId, "assetzen", 447, 595)}>
                    <Box>
                        <Tag
                            size="md"
                            bgColor="prussianBlue"
                            color="#fff"
                            textTransform="uppercase"
                            fontWeight="medium"
                        >
                            {discount}
                        </Tag>
                    </Box>
                    <Spacer />
                    {subTitle && (
                        <Text color="white" fontWeight="semibold" fontSize="sm">
                            {subTitle}
                        </Text>
                    )}
                    {title && (
                        <Heading color="white" fontWeight="bold" fontSize="lg">
                            {title}
                        </Heading>
                    )}
                    {caption && (
                        <Text color="white" fontSize="sm">
                            {caption}
                        </Text>
                    )}
                </Card>
            </Link>
        </NextLink>
    );
};

export default PerkCard;
