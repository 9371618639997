import NextLink from "next/link";
import { Box, Heading, Image, Link, Spacer, Text } from "@chakra-ui/react";
import { Card } from "../../../common";
import { formatTime } from "../../../../util/helpers";

const VideoCard = ({ bgImage, link, title, duration }) => {
    return (
        <NextLink href={link} passHref>
            <Link>
                <Card variant="video" aspectRatio={1.67 / 1} bgImage={bgImage}>
                    <Box
                        sx={{
                            zIndex: 100,
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                        }}
                    >
                        <Image h="36px" w="36px" src="../../../../images/icon_play.svg" />
                    </Box>
                    <Spacer />
                    {title && (
                        <Heading color="white" fontWeight="bold" fontSize="md" noOfLines={1}>
                            {title}
                        </Heading>
                    )}
                    {duration && (
                        <Text color="white" fontSize="sm">
                            {formatTime(duration)}
                        </Text>
                    )}
                </Card>
            </Link>
        </NextLink>
    );
};

export default VideoCard;
