import { IQueryBuilderResponse, PaginatedResponse } from './types';
import { IPackageListItem } from "../../package";
import { gql } from '@apollo/client';

export interface IGetPackageArgs {
    locationQuery?: string;
    categoryQuery?: string;
    featuredQuery?: 0 | 1;
    page?: number;
    perPage?: number;
}

export interface IGetPackagesResponse {
    packages: {
        pagination?: any;
        data: IPackageListItem[];
    }
}

const GET_PACKAGES = ({
    locationQuery = null,
    categoryQuery = null,
    featuredQuery = null,
    page = 1,
    perPage = 10,
}: IGetPackageArgs): IQueryBuilderResponse<IGetPackageArgs> => {
    return {
        query: gql`
            query getPackages($locationQuery: String, $categoryQuery: Int, $featuredQuery: String, $perPage: Int, $page: Int) {
                packages(locationQuery: $locationQuery, categoryQuery: $categoryQuery, featuredQuery: $featuredQuery, perPage: $perPage, page: $page) {
                    data {
                        id
                        name
                        alias
                        key_image
                    }
                }
            }
        `,
        variables: {
            locationQuery,
            categoryQuery,
            featuredQuery,
            page,
            perPage,
        },
    }
}

export interface IPackageTripsResponse {
    SpaGetaway: PaginatedResponse<IPackageListItem>
    HealthRetreats: PaginatedResponse<IPackageListItem>
    FitnessRetreats: PaginatedResponse<IPackageListItem>
    YogaRetreats: PaginatedResponse<IPackageListItem>
    DetoxVacations: PaginatedResponse<IPackageListItem>
}

const GET_PACKAGES_FOR_TRIPS = (): IQueryBuilderResponse => ({
    query: gql`
    query Packages($SpaGetaway: Int, $HealthRetreats: Int, $FitnessRetreats: Int, $YogaRetreats: Int, $DetoxVacations: Int, $perPage: Int, $page: Int) {
        SpaGetaway: packages(
            categoryQuery: $SpaGetaway
            perPage: $perPage
            page: $page
        ) {
            data {
                id
                alias
                key_image
                name
            }
        }
        HealthRetreats: packages(
            categoryQuery: $HealthRetreats
            perPage: $perPage
            page: $page
        ) {
            data {
                id
                alias
                key_image
                name
            }
        }
        FitnessRetreats: packages(
            categoryQuery: $FitnessRetreats
            perPage: $perPage
            page: $page
        ) {
            data {
                id
                alias
                key_image
                name
            }
        }
        YogaRetreats: packages(
            categoryQuery: $YogaRetreats
            perPage: $perPage
            page: $page
        ) {
            data {
                id
                alias
                key_image
                name
            }
        }
        DetoxVacations: packages(
            categoryQuery: $DetoxVacations
            perPage: $perPage
            page: $page
        ) {
            data {
                id
                alias
                key_image
                name
            }
        }
    }
    `,
    variables: {
        "page": 1,
        "perPage": 8,
        "SpaGetaway": 267,
        "HealthRetreats": 97,
        "FitnessRetreats": 93,
        "YogaRetreats": 115,
        "DetoxVacations": 91
    }

})


export interface IGetCategoriesResponse {
    packageCategory: {
        id: number;
        title: string;
    }[]
}

const GET_CATEGORIES = (): IQueryBuilderResponse => ({
    query: gql`
    query PackageCategory {
        packageCategory {
            id
            title
        }
    }
    `,
    variables: {},
})

export interface IGetLocationResponse {
    packageLocation: {
        id: number;
        name: number;
        path: string;
    }[]
}

const GET_LOCATIONS = (): IQueryBuilderResponse => ({
    query: gql`
    query PackageLocation {
        packageLocation {
            name
            path
        }
    }
    `,
    variables: {},
})

export type IGetSearchDropdownDataResponse = IGetCategoriesResponse & IGetLocationResponse;

const GET_SEARCH_DROPDOWN_DATA = (): IQueryBuilderResponse => ({
    query: gql`
    query getPackageSearchDropdownData {
        packageLocation {
            id
            name
            path
        }
        packageCategory {
            id
            title
        }
    }`,
    variables: {},
})
export interface IPackageSearchArgs {
    locationQuery: string;
    categoryQuery: number;
    perPage: number;
    page: number;
}
export interface IPackageSearchResponse {
    PackageSearch: PaginatedResponse<IPackageListItem>
}
const GET_PACKAGE_SEARCH = (categoryQuery: number, locationQuery: string): IQueryBuilderResponse<IPackageSearchArgs> => ({
    query: gql`
    query PackagesSearch(
        $locationQuery: String
        $categoryQuery: Int
        $perPage: Int
        $page: Int
        ) {
            PackageSearch: packages(
                locationQuery: $locationQuery
                categoryQuery: $categoryQuery
                perPage: $perPage
                page: $page
            ) {
                data {
                    id
                    alias
                    key_image
                    name
                }
            }
        }

    `,
    variables: {
        locationQuery,
        categoryQuery,
        perPage: 500,
        page: 1,
    },
});

export {
    GET_PACKAGES,
    GET_PACKAGES_FOR_TRIPS,
    GET_CATEGORIES,
    GET_LOCATIONS,

    GET_SEARCH_DROPDOWN_DATA,

    GET_PACKAGE_SEARCH
}