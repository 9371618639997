import { Heading, useStyleConfig } from "@chakra-ui/react";

export default function HeroHeader(props) {
    const { variant, text } = props;
    const styles = useStyleConfig("HeroHeader", { variant });

    return (
        <Heading sx={styles} {...props}>
            {text}
        </Heading>
    );
}
