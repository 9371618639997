import NextLink from "next/link";
import { Box, Image, Link, useMultiStyleConfig } from "@chakra-ui/react";

const PlayButton = (props) => {
    const { link, variant, ...rest } = props;
    const styles = useMultiStyleConfig("PlayButton", { variant });

    return (
        <Box sx={styles.box} {...rest}>
            <NextLink href={link.href} passHref>
                <Link>
                    <Image sx={styles.image} src="../../images/icon_play.svg" />
                </Link>
            </NextLink>
        </Box>
    );
};

export default PlayButton;
