import { Text, useStyleConfig, useBreakpointValue } from "@chakra-ui/react";

export default function TileText(props) {
    const { variant, text } = props;
    const size = useBreakpointValue({ base: "md", md: "sm", lg: "lg" });
    const styles = useStyleConfig("TileText", { variant, size });

    return (
        <Text sx={styles} {...props}>
            {text}
        </Text>
    );
}
