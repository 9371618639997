import NextLink from "next/link";
import {
    AspectRatio,
    Box,
    Flex,
    Link,
    Spacer,
    useMultiStyleConfig,
} from "@chakra-ui/react";
import { BackgroundGradient } from "../../../common";
import { CardHeader, CardText } from "../../Cards";
import { constants } from "../../../../util/constants";

const PackageCard = (props) => {
    const { backgroundSettings } = constants;
    const { aspectRatio, bgImage, link, title } = props;
    const styles = useMultiStyleConfig("Card", props);
    const gradient =
        "linear-gradient(to bottom, rgba(255, 255, 255, 0), #858266 75%, #000)";

    return (
        <Box sx={styles.card} boxShadow="primary">
            <AspectRatio ratio={aspectRatio}>
                <NextLink href={link}>
                    <Link sx={styles.link}>
                        <BackgroundGradient gradient={gradient} />
                        <Box
                            h="100%"
                            w="100%"
                            {...backgroundSettings}
                            bgImage={bgImage}
                        >
                            <Flex h="100%" w="100%" direction="column">
                                <Box>{/* TODO Card Tag */}</Box>
                                <Spacer />
                                <Box sx={{ zIndex: 100 }} px={6} pb={5}>
                                    <CardHeader
                                        variant="package"
                                        text={title}
                                        mb={6}
                                    />
                                    {/* TODO Card Categories */}
                                </Box>
                            </Flex>
                        </Box>
                    </Link>
                </NextLink>
            </AspectRatio>
        </Box>
    );
};

export default PackageCard;
