import React, { useEffect, useState } from "react";
import { AspectRatio, Box, Heading, useMultiStyleConfig } from "@chakra-ui/react";
import { BackgroundGradient } from "../../../common";
import { CardIcon } from "../../Cards";
import { constants } from "../../../../util/constants";
import { generateAssetZenURL } from "../../../../util/helpers";

const QuizOptionCard = (props) => {
    const { backgroundSettings } = constants;
    const { icon, label, bgImage, isSelected, onClick, id } = props;
    const variant = isSelected ? "quizOptionActive" : "quizOptionInactive";
    const cardStyles = useMultiStyleConfig("Card", { variant });
    const goldGradient = "linear-gradient(180deg, #85826600 0%, #858266 100%)";
    const blueGradient = "linear-gradient(180deg, #85826600 0%, #0E2B39 100%)";
    const quizOptionAssetZen = generateAssetZenURL(bgImage, "assetzen", 220, 124, 70);

    const toggleCard = () => {
        onClick(id, !isSelected);
    };

    return (
        <Box sx={cardStyles} onClickCapture={toggleCard}>
            <AspectRatio ratio={{ base: 4 / 3, sm: 16 / 9 }}>
                <Box h="100%" w="100%" {...backgroundSettings} bgImage={quizOptionAssetZen} borderRadius="lg">
                    <BackgroundGradient gradient={isSelected ? goldGradient : blueGradient} />
                    <Box h="100%" w="100%" sx={{ zIndex: 100 }} p="4px">
                        <Box
                            style={{
                                border: isSelected ? "2px solid #F3FAFF" : "initial",
                                borderRadius: "8px",
                                width: "100%",
                                height: "100%",
                                display: "grid",
                                placeItems: "center",
                                boxShadow: isSelected ? "0px 0px 5px #0090FF1C" : "initial",
                            }}
                        >
                            <Box>
                                <Box
                                    align="center"
                                    style={{
                                        transition: "all 0.3s ease-out",
                                        color: isSelected ? "#858266" : "white",
                                        border: isSelected ? "2px solid #858266" : "initial",
                                        backgroundColor: isSelected ? "#FFFFFF" : "initial",
                                    }}
                                    mx="auto"
                                    mb={1}
                                    borderRadius={"50%"}
                                    borderWidth="2px"
                                    h={{ base: "45px", md: "60px" }}
                                    w={{ base: "45px", md: "60px" }}
                                    display="grid"
                                    placeItems="center"
                                >
                                    <CardIcon
                                        icon={icon}
                                        margin={"auto"}
                                        isSelected={isSelected}
                                        style={{
                                            transition: "all 0.3s ease-out",
                                            position: "relative",
                                            top: isSelected ? "0px" : "8px",
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Heading
                                        fontSize={{ base: "11px", md: "sm" }}
                                        fontWeight="300"
                                        letterSpacing="3px"
                                        textAlign="center"
                                        color="#fffef7"
                                    >
                                        {label}
                                    </Heading>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </AspectRatio>
        </Box>
    );
};

export default QuizOptionCard;
