import React, { createContext, ReactNode, useContext, useState } from "react";
import { PopupProps } from "../../components/common/Popup/Popup";

interface PopupContext {
    popupList: PopupProps[];
    showPopup(popup: PopupProps): void;
    remove(popup: PopupProps): void;
}

const popupContext = createContext(null);
const { Provider } = popupContext;

export function PopupProvider(props: { children: ReactNode }): JSX.Element {
    const _popup = usePopupProvider();
    return <Provider value={_popup}>{props.children}</Provider>;
}

export const usePopup: () => PopupContext = () => {
    return useContext(popupContext) as PopupContext;
};

const _getRandomID = () => Math.random().toString(36).substring(2, 7);

const usePopupProvider = (): PopupContext => {
    const [popupList, setPopup] = useState<PopupProps[]>([]);

    const showPopup = (popup: PopupProps) => {
        console.groupCollapsed("adding popup");
        console.log(popupList, popup);
        setPopup((oldList) => [...oldList, { ...popup, key: _getRandomID() }]);
        console.log(popupList, popup);
        console.groupEnd();
    };

    const remove = (popup: PopupProps) => {
        console.log(`removing ${popup.message}`);
        setPopup((oldList) => {
            return oldList.map((_popup) => {
                if (_popup.key === popup.key) {
                    _popup.dismissed = true;
                }
                return _popup;
            });
        });
    };

    return {
        popupList,
        showPopup,
        remove,
    };
};
