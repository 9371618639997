import { Heading, useStyleConfig, useBreakpointValue } from "@chakra-ui/react";

const CardHeader = (props) => {
    const { variant, text, ...rest } = props;
    const styles = useStyleConfig("CardHeader", { variant });

    return (
        <Heading as={"h5"} size={"sm"} sx={styles} {...rest}>
            {text}
        </Heading>
    );
};

export default CardHeader;
