import React from "react";
import { Box, Flex, Spacer, Text } from "@chakra-ui/react";
import styles from "./EmailCaptureForm.module.scss";
class EmailCapture extends React.Component {
    componentDidMount() {
        const script = document.createElement("script");
        script.src = "https://js.hsforms.net/forms/v2.js";
        document.body.appendChild(script);

        script.addEventListener("load", () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    region: "na1",
                    portalId: "8139138",
                    css: "",
                    formId: "a632ca6b-6851-44f2-a294-bf6f3857d572",
                    target: "#hubspotForm",
                });
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                <Flex direction={{ base: "column", md: "row" }}>
                    <Box maxW={300}>
                        <Text
                            color="white"
                            fontSize={{ base: "36px", md: "46px" }}
                            fontFamily="Brittany Signature"
                            pb={5}
                        >
                            {this.props.headline}
                        </Text>
                        <Text
                            color="white"
                            fontSize={{ base: "16px" }}
                            lineHeight="1"
                            pb={{ base: 8, md: 0 }}
                        >
                            {this.props.copy}
                        </Text>
                    </Box>
                    <Flex
                        className={styles.emailCaptureForm}
                        direction="column"
                    >
                        <Spacer />
                        <div
                            className={styles.hubspotForm}
                            id="hubspotForm"
                        ></div>
                    </Flex>
                </Flex>
            </React.Fragment>
        );
    }
}

export default EmailCapture;
