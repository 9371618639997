import { AspectRatio, Image, useStyleConfig } from "@chakra-ui/react";

export default function TileImage(props) {
    const { variant, src, alt } = props;

    const styles = useStyleConfig("TileImage", { variant });

    return (
        <AspectRatio ratio={1}>
            <Image sx={styles} src={src} alt={alt} />
        </AspectRatio>
    );
}
