import { useEffect } from "react";

export default function CrowdriffGallery(props) {
    const { id } = props;

    useEffect(() => {
        const script = document.createElement("script");

        script.src = "https://starling.crowdriff.com/js/crowdriff.js";
        script.async = true;
        script.id = id;

        document.getElementById("crowdriffGallery").appendChild(script);

        // return () => {
        //     document.getElementById("crowdriffGallery").removeChild(script);
        // };
    }, [id]);

    return <div id="crowdriffGallery"></div>;
}
