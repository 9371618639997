import { Text, useStyleConfig } from "@chakra-ui/react";

export default function HeroText(props) {
    const { variant, text, ...rest } = props;
    const styles = useStyleConfig("HeroText", { variant });

    return (
        <Text sx={styles} {...rest}>
            {text}
        </Text>
    );
}
