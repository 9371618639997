import { Heading, useStyleConfig, useBreakpointValue } from "@chakra-ui/react";

export default function TileSubheader(props) {
    const { variant, text } = props;
    const size = useBreakpointValue({ base: "md", md: "sm", lg: "lg" });
    const styles = useStyleConfig("TileSubheader", { variant, size });

    return (
        <Heading as="h5" sx={styles} {...props}>
            {text}
        </Heading>
    );
}
