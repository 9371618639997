import { Box, Container, Flex, IconButton, Select, Skeleton } from "@chakra-ui/react";
import { Button } from "../";
import { SearchIcon } from "@chakra-ui/icons";
import { GET_SEARCH_DROPDOWN_DATA, IGetSearchDropdownDataResponse } from "@/services/gql/queries/package.queries";
import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";

interface InteractiveSearchToolbarProps {
    categoryQuery?: number;
    locationQuery?: string;
    redirect?: boolean;
    onSearch?: (categoryQuery: number, locationQuery: string) => void;
}

export function InteractiveSearchToolbar(props: InteractiveSearchToolbarProps) {
    const router = useRouter();
    const [categoryQuery, setCategoryQuery] = useState<number>();
    const [locationQuery, setLocationQuery] = useState<string>();

    const { query } = GET_SEARCH_DROPDOWN_DATA();
    const { data, loading } = useQuery<IGetSearchDropdownDataResponse>(query);

    const searchAction = (e?) => {
        if (e) e.preventDefault();

        if (props.onSearch) {
            props.onSearch(categoryQuery, locationQuery);
        }
        const queryString = new URLSearchParams();
        if (categoryQuery) queryString.set("category", categoryQuery.toString());
        if (locationQuery) queryString.set("location", locationQuery);

        router.push(`/trips/search?${queryString}`, undefined, {
            shallow: !!!props.redirect,
        });
    };

    useEffect(() => {
        if (props.categoryQuery) {
            setCategoryQuery(props.categoryQuery);
        }
        if (props.locationQuery) {
            setLocationQuery(props.locationQuery);
        }
    }, [props.categoryQuery, props.locationQuery]);

    const backgroundSettings = {
        mixBlendMode: "multiply",
        backgroundBlendMode: "multiply",
        backgroundImage: "linear-gradient(to bottom, #0e2a39, #0e2a39)",
    };

    return (
        <Box sx={backgroundSettings}>
            <Container maxW="container.lg" pb={{ base: "24px", md: "0" }}>
                <Flex direction={{ base: "column", md: "row" }}>
                    <Select
                        iconColor="white"
                        variant="unstyled"
                        sx={{
                            color: "white",
                            borderBottom: "1px dashed white",
                            borderRadius: "none",
                            textTransform: "uppercase",
                            padding: "12px 0",
                        }}
                        placeholder="Select Your Type of Experience"
                        my={{ base: "20px", md: "24px", lg: "30px" }}
                        mr={{ base: "0", md: "65px" }}
                        defaultValue={categoryQuery}
                        value={categoryQuery}
                        onChange={(e) =>
                            isNaN(parseInt(e.target.value))
                                ? setCategoryQuery(undefined)
                                : setCategoryQuery(parseInt(e.target.value))
                        }
                    >
                        {data &&
                            data.packageCategory.map((category) => {
                                return (
                                    <option key={category.id} value={category.id} style={{ textTransform: "none" }}>
                                        {category.title}
                                    </option>
                                );
                            })}
                    </Select>
                    <Select
                        iconColor="white"
                        variant="unstyled"
                        sx={{
                            color: "white",
                            borderBottom: "1px dashed white",
                            borderRadius: "none",
                            textTransform: "uppercase",
                            padding: "12px 0",
                        }}
                        placeholder="Select Your Destination"
                        my={{ base: "20px", md: "24px", lg: "30px" }}
                        ml={{ base: "0", md: "65px" }}
                        value={locationQuery}
                        onChange={(e) => setLocationQuery(e.target.value)}
                    >
                        {data &&
                            data.packageLocation.map((location) => {
                                return (
                                    <option key={location.id} value={location.path} style={{ textTransform: "none" }}>
                                        {location.name}
                                    </option>
                                );
                            })}
                    </Select>
                    <Flex
                        direction="row"
                        pl={{ base: 0, md: "65px" }}
                        alignItems="center"
                        justifyContent={{ base: "flex-end", md: "center" }}
                        w={{ base: "100%", md: "auto" }}
                    >
                        {loading ? (
                            <Skeleton width="50px" height="50px" display={{ base: "none", md: "block" }} />
                        ) : (
                            <IconButton
                                aria-label="search"
                                size="lg"
                                colorScheme="gold"
                                onClick={loading ? null : searchAction}
                                display={{ base: "none", md: "block" }}
                                icon={<SearchIcon />}
                            />
                        )}
                        <Box display={{ base: "block", md: "none" }}>
                            <Button
                                color="sand"
                                colorScheme="gold"
                                isLoading={loading}
                                label="Search"
                                onClick={loading ? null : searchAction}
                            />
                        </Box>
                    </Flex>
                </Flex>
            </Container>
        </Box>
    );
}

/**
 * @deprecated This is just a UI component; please use SearchToolbar/InteractiveSearchToolbar instead
 *
 * @param {*} props
 * @return {*}
 */
function SearchToolbar(props) {
    const backgroundSettings = {
        mixBlendMode: "multiply",
        backgroundBlendMode: "multiply",
        backgroundImage: "linear-gradient(to bottom, #0e2a39, #0e2a39)",
    };

    const options = ["Option 1", "Option 2", "Option 3"];

    const selectOptions = options.map((option) => {
        return (
            <option value={option} style={{ textTransform: "none" }}>
                {option}
            </option>
        );
    });

    return (
        <Box sx={backgroundSettings}>
            <Container maxW="container.lg" pb="10px">
                <Flex direction={{ base: "column", md: "row" }}>
                    <Select
                        iconColor="white"
                        variant="unstyled"
                        sx={{
                            color: "white",
                            borderBottom: "1px dashed white",
                            borderRadius: "none",
                            textTransform: "uppercase",
                            padding: "12px 0",
                        }}
                        placeholder="Select Your Type of Experience"
                        my={{ base: "20px", md: "24px", lg: "30px" }}
                        mr={{ base: "0", md: "65px" }}
                    >
                        {selectOptions}
                    </Select>
                    <Select
                        iconColor="white"
                        variant="unstyled"
                        sx={{
                            color: "white",
                            borderBottom: "1px dashed white",
                            borderRadius: "none",
                            textTransform: "uppercase",
                            padding: "12px 0",
                        }}
                        placeholder="Select Your Destination"
                        my={{ base: "20px", md: "24px", lg: "30px" }}
                        ml={{ base: "0", md: "65px" }}
                    >
                        {selectOptions}
                    </Select>
                    <Flex direction="row" pl="65px" alignItems="center">
                        <Button maxH="50px" maxW="50px" p="10px">
                            <SearchIcon h="30px" w="30px" />
                        </Button>
                    </Flex>
                </Flex>
            </Container>
        </Box>
    );
}

export default SearchToolbar;
