/**
 *
 * This is an reusable loading page component. Just shows a spinner on a page with a gradient.
 *
 */
import React from "react";
import { Box, Center, Container, Text } from "@chakra-ui/react";
import { LoadingSpinner } from "../../common";

export default function LoadingPage(props) {
    const { text } = props;

    return (
        <React.Fragment>
            <Container m="auto">
                <Center h="100%" w="100%">
                    <Box textAlign="center" my="100px" sx={{ zIndex: 100, padding: "100px" }}>
                        <LoadingSpinner />
                        {text && (
                            <Text mt={3} size="md" color="prussianBlue">
                                {text}
                            </Text>
                        )}
                    </Box>
                </Center>
            </Container>
        </React.Fragment>
    );
}
