import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { Hero } from "components/common";

const OnboardingPage = ({ children }) => {
    return (
        <React.Fragment>
            <Hero aspectRatio="none" noGradient />
            <Flex
                bgImage="https://i.assetzen.net/i/74e7yrQpaabf/w:1280/h:624/q:70.jpg"
                bgPosition="center"
                bgSize="cover"
                bgRepeat="no-repeat"
                position="relative"
                minHeight="100%"
                width="100%"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                flexGrow={1}
            >
                <Box
                    h="100%"
                    w="100%"
                    position="absolute"
                    background="linear-gradient(rgba(16, 54, 92, 0.8), rgba(16, 54, 92, 0.8))"
                />
                <Box>{children}</Box>
            </Flex>
        </React.Fragment>
    );
};

export default OnboardingPage;
