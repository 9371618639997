/**
 * SignUpButton Component
 *
 * Extends chakra 'Button' component to create a Stripe checkout session to purchase
 * a membership subscription.
 *
 */

import React from "react";
import NextLink from "next/link";
import { useAuth } from "@/services/hooks/useAuth";
import { useApi } from "@/services/hooks/useApi";
import { Button, Link } from "@chakra-ui/react";

export default function SignUpButton(props) {
    // Load the Firebase user object from useAuth
    const { user, isVoyager } = useAuth();

    // Load Stripe features to gain access to the checkout method
    const { stripeService } = useApi();

    // Declare button properties
    let buttonLabel, buttonLink, buttonOnClick;

    // Check for user scenarios, and modify button display based on state
    if (user && isVoyager) {
        // Logged-in Voyager
        [buttonLabel, buttonLink, buttonOnClick] = ["Go to Dashboard", "/", () => null];
    } else if (user && !isVoyager) {
        // Logged-in Standard
        [buttonLabel, buttonLink, buttonOnClick] = [
            "Upgrade",
            "#",
            (e) => {
                e.preventDefault();
                stripeService.subscribeToMembership(user);
            },
        ];
    } else {
        // Logged Out
        [buttonLabel, buttonLink, buttonOnClick] = ["Join Now", "/join", () => null];
    }

    return (
        <NextLink href={buttonLink} passHref>
            <Link>
                <Button
                    as="button"
                    onClick={buttonOnClick}
                    variant={props.variant}
                    colorScheme={props.colorScheme}
                    color={props.color}
                >
                    {buttonLabel}
                </Button>
            </Link>
        </NextLink>
    );
}
