import React from "react";

import { Box, Text } from "@chakra-ui/react";

interface IBlurredTextRequiredProps {}

interface IBlurredTextOptionalProps {
    children?: JSX.Element | JSX.Element[];
    color?: string;
    numParagraphs?: number;
    text?: string;
}

interface IBlurredTextProps extends IBlurredTextRequiredProps, IBlurredTextOptionalProps {}

const defaultProps: IBlurredTextOptionalProps = {
    color: "",
    numParagraphs: 0,
    text: "",
};

const BlurredText = (props: IBlurredTextProps) => {
    const { color, numParagraphs, text, children } = props;
    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";

    let paragraphs = [];
    for (let i = 0; i < numParagraphs; i++) {
        paragraphs.push(
            <Text color={color} sx={{ filter: "blur(4px)" }}>
                {loremIpsum}
            </Text>
        );
    }

    return (
        <Box position="relative">
            {text && (
                <Text color={color} sx={{ filter: "blur(4px)" }}>
                    {text}
                </Text>
            )}
            {numParagraphs > 0 && paragraphs}
            {children && (
                <Box
                    zIndex="docked"
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    textAlign="center"
                >
                    {children}
                </Box>
            )}
        </Box>
    );
};

BlurredText.defaultProps = defaultProps;

export default BlurredText;
