import NextLink from "next/link";
import {
    AspectRatio,
    Box,
    Flex,
    Link,
    useMultiStyleConfig,
} from "@chakra-ui/react";
import { BackgroundGradient } from "../../../common";
import { CardHeader, CardText } from "../../Cards";
import { constants } from "../../../../util/constants";

const WellnessCard = (props) => {
    const { backgroundSettings } = constants;
    const { bgImage, link, subTitle, title } = props;
    const styles = useMultiStyleConfig("Card", props);
    const gradient =
        "linear-gradient(to right, #858266, rgba(255, 255, 255, 0), #858266)";

    return (
        <Box sx={styles}>
            <NextLink href={link.href} passHref>
                <Link sx={styles.link} isExternal>
                    <AspectRatio ratio={1.5 / 1}>
                        <Box sx={styles.card} boxShadow="primary">
                            <BackgroundGradient gradient={gradient} />
                            <Box
                                h="100%"
                                w="100%"
                                {...backgroundSettings}
                                bgImage={bgImage}
                            />
                        </Box>
                    </AspectRatio>
                    <Flex direction="row" flexWrap="wrap">
                        <Box w="100%" mt={3}>
                            <CardHeader
                                variant="wellness"
                                noOfLines={2}
                                text={title}
                            />
                            <CardText
                                variant="wellness"
                                noOfLines={2}
                                text={subTitle}
                            />
                        </Box>
                    </Flex>
                </Link>
            </NextLink>
        </Box>
    );
};

export default WellnessCard;
