import { Text, useStyleConfig, useBreakpointValue } from "@chakra-ui/react";

const CardText = (props) => {
    const { variant, text } = props;
    // const size = useBreakpointValue({ base: "md", md: "sm", lg: "lg" });
    const styles = useStyleConfig("CardText", { variant });

    return (
        <Text sx={styles} {...props}>
            {text}
        </Text>
    );
};

export default CardText;
