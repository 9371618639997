import React from "react";
import NextLink from "next/link";
import { Flex, Heading, Link, useMultiStyleConfig, useBreakpointValue } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";

const TileHeader = (props) => {
    const { variant, text, hasLink, link, ...rest } = props;

    const size = useBreakpointValue({
        base: "xs",
        sm: "sm",
        md: "md",
        lg: "lg",
        xl: "xl",
    });

    const styles = useMultiStyleConfig("TileHeader", { size, variant });

    return (
        <Flex
            direction={{ base: hasLink && "column", md: hasLink && "row" }}
            justifyContent={hasLink && "space-between"}
            {...rest}
        >
            <Heading sx={styles.heading} pb={{ base: "8px", md: 0 }}>
                {text}
            </Heading>
            {hasLink && (
                <Flex justifyContent="flex-end">
                    <NextLink href={link.href} passHref>
                        <Link sx={styles.link}>
                            {link.text} <ArrowForwardIcon sx={styles.icon} />
                        </Link>
                    </NextLink>
                </Flex>
            )}
        </Flex>
    );
};

export default TileHeader;
